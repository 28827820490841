import EntityForm from "@/mixins/EntityForm";
import Component, {
  mixins
} from "vue-class-component";

@Component({
  inject: {
    languageService: "languageService",
    eventService: "eventService",
    userService: "userService",
    privacyService: "privacyService",
  },
})
export default class PrivacyRevForm extends mixins(EntityForm) {
  model = {};
  platformLanguages = [];

  get elements() {
    return [
      {
        id: "checkBoxDescription",
        type: "paragraph",
        text: this.translations.labels.privacy_form_newRevDescription,
        columns: 1,
        hide: () => {
          return !this.isEditMode;
        },
      },
      {
        id: "keepRev",
        label: this.translations.labels.privacy_form_newRev,
        type: "checkbox",
        columns: 1,
        hide: () => {
          return !this.isEditMode;
        },
      },
      {
        id: "type",
        label: this.translations.labels.privacy_form_type,
        type: "select",
        items: this.types,
        required: true
      },
      {
        id: "isRequired",
        label: this.translations.labels.privacy_form_required,
        type: "switch"
      },
      {
        id: "localizations",
        type: "localizations",
        header: this.translations.labels.form_localizations,
        languages: this.languages,
        resetColumns: true,
        elements: [{
            id: "title",
            label: this.translations.labels.privacy_form_title,
            type: "text",
            rules: "max:200",
            required: this.isText(),
            hide: () => {
              return !this.isText();
            },
          },
          {
            id: "label",
            label: this.translations.labels.privacy_form_wording,
            type: "text",
            rules: "max:200",
            required: true
          },
          {
            id: "description",
            label: this.translations.labels.privacy_form_description,
            type: "wysiwyg",
            required: this.isText(),
            resetColumns: 1,
            hide: () => {
              return !this.isText();
            },
          }
        ],
      },
    ];
  }

  get types() {
    return this.privacyService.getPrivacyTypes();
  }

  get languages() {
    return this.platformLanguages;
  }

  get isEditMode(){
    return !!this.$route.params.idPrivacyRev;
  }

  get helpLink() {
    return this.translations.pageHelp.privacyRevision;
  }

  isText() {
    return this.model.type == "Text";
  }

  // eslint-disable-next-line no-empty-function
  afterCreate() {}

  async created() {
    this.platformLanguages = await this.languageService.list();
    this.afterCreate();
  }
}