<script>
import PrivacyRevForm from "@/views/menu/privacy/revisions/privacyRevForm";
import Component, { mixins } from "vue-class-component";
import { toastService } from "@/services/toastService.js";

@Component({
  inject: {
    privacyService: "privacyService",
  },
})
export default class PrivacyRevNew extends mixins(PrivacyRevForm) {
  formId = "new-privacy-rev-form";

  get fieldColumns() {
    return 2;
  }
  get actions() {
    return [
      {
        id: "save",
        type: "submit",
        text: this.translations.labels.button_save,
        leftIcon: "mdi-content-save-outline",
        handle: this.save,
      },
    ];
  }
  async save(passesValidation) {
    this.errors = [];
    if (!passesValidation) {
      return;
    }
    try {
      await this.privacyService.addRev(this.idPrivacy,{
        type: this.model.type,
        required: this.model.isRequired,
        localizations: this.model.localizations,
      });
      this.$router.push(`/privacy/revisions/${this.idPrivacy}/list`);
      toastService.success(this.translations.success.privacyRev_create);
    } catch (error) {
      this.errors = this.formatApiErrors(error);
    }
  }

  get idPrivacy(){
    return this.$route.params.idPrivacy;
  }

  getFormTitle() {
    return this.translations.pageTitles.privacyRev_new;
  }

  afterCreate () {
    this.model = {
      code: null,
      type: null,
      isRequired: false,
      localizations: [],
    };
    this.isReady = true;
  }
}
</script>
